<template>
  <div class="about">
    <h1>This is an about page ABC</h1>
    <button v-if="updateExists" @click="refreshApp">
      New version available! Click to update
    </button>
    <button @click="testBackend">
      Test backend
    </button>
    <button @click="testLocalStorage">
      Test Local Storage
    </button>
  </div>
</template>
<script>
import Axios from "axios";
//import { User } from "../store/models";

export default {
  name: "About",
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    };
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        console.log("About this.refreshing");
        return;
      }
      console.log("About this.refreshing false, reload");
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    showRefreshUI(e) {
      console.log("About showRefreshUI");
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        console.log("About refreshApp !");
        return;
      }
      console.log("About refreshApp post skipWaiting");
      this.registration.waiting.postMessage("skipWaiting");
    },
    async testBackend() {
      console.log("API_URL=", process.env.VUE_APP_API_URL);
      await Axios.post(process.env.VUE_APP_API_URL + "api/login", {
        email: "piotr.plonka@prym-soft.pl"
      }).then(response => {
        console.log("success=", response.data.success);
        alert("Z serwera:", response.data.success);
      });
    },
    testLocalStorage() {
      //const user = new User("piotrek.plonka@gmail.com", "Piotr", "Płonka");
    }
  }
};
</script>
